import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';

import {CacheService} from './cache_service';
import {UserService} from './user_service';

import {TeamSubscriptionPlan} from '../types/team-subscription-plan';

import { catchError, map, Subject} from 'rxjs';
import {Util} from '../util/util';

@Injectable()
export class TeamService {
  subscriptionStatusUpdateSubject: Subject<any> = new Subject();

  constructor(
    private http: HttpClient,
    private router: Router,
    private cacheService: CacheService,
    private userService: UserService,
    private utilities: Util) {
  }

  createTeam(teamId: string, name: string, description: string): Promise<any> {
    const params = new HttpParams()
      .set('teamId', teamId)
      .set('description', description)
      .set('name', name);

    return this.http.post('/api/user/team/create', params)
      .toPromise().catch((err) => {
        console.error('Error creating a team', err);
        throw err;
      });
  }

  deleteTeam(teamId: string): Promise<any> {
    return this.http.delete(`/api/team/${teamId}/remove`, {})
      .toPromise()
      .catch(err => {
        console.error('Error removing Admin', err);
        throw err;
      });
  }

  getTeamChannels(teamId: string): Promise<any> {
    return this.http.get(`/api/team/${teamId}/channels`)
      .toPromise().catch((err) => {
        console.error('Error retrieving getting team channels', err);
        throw err;
      });
  }

  getTeamProfile(teamId: string): Promise<any> {
    return this.http.get('/api/team/' + teamId + '/profile')
      .toPromise().catch((err) => {
        console.error('Error retrieving team profile', err);
        throw err;
      });
  }

  getCurrentTeam(force = false): Promise<any> {
    const cache = this.cacheService.getTeam();

    if (cache && !force) {
      return Promise.resolve(cache);
    }

    return this.http.get('/api/user/team/current')
      .pipe(
        map(resp => {
          this.cacheService.setTeam(resp);
          return resp;
        }
      ))
      .toPromise().catch((err) => {
        console.error('Error retrieving current team', err);
        throw err;
      });
  }

  inviteMember(teamId: string, email: string): Promise<any> {
    return this.http.post('/api/team/' + teamId + '/member/invite/' + email, {})
      .toPromise().catch((err) => {
        console.error('Error inviting member', err);
        throw err;
      });
  }

  setAdmin(teamId: string, userId: string): Promise<any> {
    return this.http.put('/api/team/' + teamId + '/member/admin/' + userId, {})
      .toPromise().catch((err) => {
        console.error('Error making Admin', err);
        throw err;
      });
  }

  unsetAdmin(teamId: string, userId: string): Promise<any> {
    return this.http.delete('/api/team/' + teamId + '/member/admin/' + userId, {})
      .toPromise().catch((err) => {
        console.error('Error removing Admin', err);
        throw err;
      });
  }

  removeMember(teamId: string, userId: string): Promise<any> {
    return this.http.delete('/api/team/' + teamId + '/member/remove/' + userId, {})
      .toPromise().catch((err) => {
        console.error('Error removing Admin', err);
      });
  }

  updateTeam(teamId: string, name: string, description: string): Promise<any> {
    const params = new HttpParams()
      .set('description', description)
      .set('name', name);

    return this.http.post('/api/team/' + teamId + '/profile/update', params)
    .pipe(
      map( (team: any) => {
        const currTeamArr = this.cacheService.getTeam();
        if (currTeamArr) {
          for (let i = 0; i < currTeamArr.length; i++) {
            if (currTeamArr[i].id === team.id) {
              currTeamArr[i] = team;
              this.cacheService.setTeam(currTeamArr);
              break;
            }
          }
        }
        return team;
      }),
      catchError( (err) => {
        console.error('Error updating team', err);
        throw err;
      })
    )
    .toPromise();
  }


  getTeamSubscriptionPlans(teamId: string, force = false): Promise<TeamSubscriptionPlan> {
    const cache = this.cacheService.getTeamSubscriptionPlans(teamId);

    if (cache && !force) {
      return Promise.resolve(cache);
    } else {
      return this.http.get( '/api/team/' + teamId + '/subscription/plans' , {})
        .toPromise().then((plans: any) => {
          this.cacheService.setTeamSubscriptionPlans(teamId, plans);
          return plans;
        }, err => {
          console.error('Error getting team plans', err);
          throw err;
        });
    }
  }

  getTeamSubscriptionStatus(teamId: string): Promise<any> {
    const url = '/api/team/' + teamId + '/subscription/status';
    return this.http.get(url).toPromise();
  }

  getTeamSubscriptionToken(steamId: string | number, period: string) {
    if (period === 'YEAR') {
      period = 'ANNUAL';
    }

    if (period === 'MONTHL') {
      period = 'MONTHLY';
    }
    return this.http.get(`/api/team/${steamId}/subscription/token/${period}`).toPromise();
  }

  validateSubscription(token: string, teamId: string) {
    const params = new HttpParams().set('token', token);

    return this.http.post(`/api/team/${teamId}/subscription/validate`, params)
      .toPromise().then(
        response => response,
        err => err
      )
      .catch(err => {
        console.error('Error ', err);
      });
  }

  subscriptionStatusUpdate(status: any) {
    this.subscriptionStatusUpdateSubject.next(status);
  }

  putPrice(teamId: string, priceInCents: string, period: string, active: number) {
    if (period === 'YEAR') {
      period = 'YEARLY'; // PROVISIONAL
    }

    const params = new HttpParams()
      .set('price', priceInCents)
      .set('period', period)
      .set('active', active)

    return this.http.put('/api/team/' + teamId + '/subscription/plan' , params)
      .pipe(
        map(resp => {
          this.getTeamSubscriptionPlans(teamId, true);
          return resp;
        }
        )
      ).toPromise();
  }

  acceptInvitation(teamId: string, token: string) {
    return this.http.get('/api/team/' + teamId + '/member/invite/accept/' + token , {})
      .toPromise()
      .catch((err) => {
        console.error('Error accepting invitation', err);
        throw err;
      });
  }

  public subscriptionRedirectPayment(
    teamId: number | string,
    period: string,
    returnUrl: string,
    shareEmail?: boolean
  ): Promise<any> {
    let params = new HttpParams()
      .set('method', 'GET')
      .set('url', returnUrl);

    if (shareEmail !== undefined) {
      params = params.set('share_email', shareEmail ? '1': '0');
    }  
  
    return this.http
      .get(`/api/team/${teamId}/subscription/token/${period}`, { params })
      .toPromise();
  }

  public deleteTeamMember(teamId: string, userId: string): Promise<any> {
    return this.http.delete(`/api/team/${teamId}/member/remove/${userId}`, {})
      .toPromise()
      .catch(err => {
        console.error('Error while removing memeber of a team', err);
        throw err;
      });
  }

  public uploadTeamIconImage(fileToUpload: any, teamId: string): Promise<any> {
    const formData: FormData = new FormData();

    formData.append('name', fileToUpload.name);
    formData.append('file', fileToUpload);

    return this.http.post<any>(`/api/team/${teamId}/profile/image/icon`, formData).toPromise();
  }

  public uploadTeamBannerImage(fileToUpload: any, teamId: string): Promise<any> {
    const formData: FormData = new FormData();

    formData.append('name', fileToUpload.name);
    formData.append('file', fileToUpload);

    return this.http.post<any>(`/api/team/${teamId}/profile/image/banner`, formData).toPromise();
  }

}
